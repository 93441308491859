// NB: These are used directly in the browser url, so they must be pretty (e.g. no postfix) even if
// we lose a bit of type checking versus other enums that also contain 'MOVIE' and 'TVSHOW' etc.
export const PaymentPagePurposes = {
  PURCHASE: 'kjøp',
  CHANGE: 'endre',
  REACTIVATE: 'reaktiver',
  RESTART_RENEWAL: 'fortsett',
  REVERT_PENDING_CHANGE: 'behold',
} as const;

type ValueOf<T> = T[keyof T];

export type PaymentPagePurpose = keyof typeof PaymentPagePurposes;
export type PaymentPagePurposePath = ValueOf<typeof PaymentPagePurposes>;

const pathPurposeMap = new Map(Object.entries(PaymentPagePurposes).map(([key, value]) => [value, key])) as ReadonlyMap<
  PaymentPagePurposePath,
  PaymentPagePurpose
>;

export const getPurposeByPath = (path: PaymentPagePurposePath): PaymentPagePurpose | undefined =>
  pathPurposeMap.get(path);
