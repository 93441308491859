import API from '@strim/gateway-api';

import { AssetDetails } from '@rikstv/play-common/src/forces/assetDetails/assetDetails.types';
import { authService } from '@rikstv/play-common/src/utils/auth/AuthService';
import { getLogger } from '@rikstv/play-common/src/utils/logger/logger';

import { postProductRecommendationFromEntitlements } from '../../../apis/StrimGatewayAPI';
import routerPath from '../../../common/router/router.path';
import { PaymentPagePurposes } from '../../payment/containers/paymentpage.types';
import { PACKAGE_URLS } from '../forces/constants';

const logger = getLogger('[productRecommendation]');

const getRecommendedProducts = async ({
  entitlementKeys = [],
  channelExternalIds = [],
}: {
  entitlementKeys?: string[];
  channelExternalIds?: number[];
}) => {
  let recommendedProducts: API.ProductCombination | undefined;
  try {
    // send entitlementKeys to customerApi to get a package recommendation
    const recommendedProductsResponse: API.ProductCombinationResponse = await postProductRecommendationFromEntitlements(
      entitlementKeys,
      channelExternalIds,
      authService.getToken().value
    );

    const recommendedProductCombinations = recommendedProductsResponse.productCombinations;

    recommendedProducts = recommendedProductCombinations?.at(0);

    logger.log('got recommended product combinations', {
      recommendedProducts: recommendedProducts,
      all: recommendedProductCombinations,
    });
  } catch (err) {
    logger.warn('get recommendedPackages error', err);
  }

  return recommendedProducts;
};

export const getNavigationUrlAndRecommendedProducts = async ({
  channelExternalIds,
  assetDetails,
}: {
  channelExternalIds?: number[];
  assetDetails?: AssetDetails;
}) => {
  const recommendedProducts = await getRecommendedProducts({
    entitlementKeys: assetDetails?.entitlementKeys,
    channelExternalIds,
  });

  logger.info(
    `Got recommendations ${recommendedProducts} for ${assetDetails?.entitlementKeys} / ${channelExternalIds}`
  );

  if (!recommendedProducts) {
    if (authService.isAuthenticated()) {
      logger.info('Did not receive any recommended products. Authenticated, so redirecting to accountSubscriptionPage');
      return { recommendedProducts: null, url: routerPath.accountSubscriptionPage() };
    } else {
      logger.info('Did not receive any recommended products. NOT authenticated, so redirecting to signUpSelectPackage');
      return { recommendedProducts: null, url: routerPath.signUpSelectPackage() };
    }
  }

  if (authService.isAuthenticated()) {
    logger.info(
      `Received recommended products ${recommendedProducts}. Authenticated, so redirecting to purchaseProducts`
    );
    return {
      recommendedProducts,
      url: routerPath.purchaseProducts(recommendedProducts.products, PaymentPagePurposes.CHANGE),
      cmsUrl: PACKAGE_URLS[recommendedProducts.packageId], // Will not work in cases where customer has addon product and needs to upgrade from "mye" to "mest"
    };
  } else {
    logger.info(
      `Received recommended products ${recommendedProducts}. NOT authenticated, so redirecting to signUpWithPackage`
    );
    return {
      recommendedProducts,
      url: routerPath.signUpWithProducts(recommendedProducts.products.map(productId => ({ id: productId }))),
      cmsUrl: PACKAGE_URLS[recommendedProducts.packageId],
    };
  }
};
