import { configEnvs } from '@rikstv/play-common/src/config';
import { envs } from '@rikstv/play-common/src/config/apps/config.utils';
import { getEnvVariables } from '@rikstv/play-common/src/config/getEnvVariables';

const ENV = getEnvVariables();
const config = {
  stsResetPasswordPath: '/Account/ResetPassword',
  gatewayUrl: configEnvs.forEnv({
    [envs.PROD]: 'https://gw.strim.no',
    [envs.PT]: ENV.VITE_APP_GW_OVERRIDE || 'https://gw.strim.no',
    [envs.TEST]: ENV.VITE_APP_GW_OVERRIDE || 'https://gw.uat.strim.no',
    [envs.UAT]: ENV.VITE_APP_GW_OVERRIDE || 'https://gw.uat.strim.no',
    [envs.DEV]: ENV.VITE_APP_GW_OVERRIDE || 'https://gw.dev.strim.no',
  }),
  enablePosthog: configEnvs.forEnvWithDefault(
    {
      PROD: true,
      UAT: true,
    },
    false
  ),
  emailAdresses: {
    kontakt: 'kontakt@strim.no',
    personvern: 'personvern@rikstv.no',
  },
  uxSignal: {},
} as const;

export default config;
