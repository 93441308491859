import { PaymentPagePurposes } from '../../domain/payment/containers/paymentpage.types';

import { routerPaths } from './router.path';

export const isAccountSubscriptionPage = (location: Location) => {
  return location.pathname.includes(encodeURI(routerPaths.accountSubscriptionPage));
};
export const isPaymentPage = (location: Location) => {
  const paths = Object.entries(PaymentPagePurposes).map(([_, value]) => encodeURI(value));
  return new RegExp(`/min-konto/(${paths.join('|')})+`).test(location.pathname);
};

export const isEmailPage = (location: Location) => {
  return location.pathname.startsWith(routerPaths.changeEmail);
};

export const isVippsReturnPage = (location: Location) => {
  return location.pathname.startsWith(routerPaths.vippsReturnUrl);
};
