import { captureExceptionInSentry } from '../../utils/errorTracker/tracking';

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    captureExceptionInSentry(error, {
      fingerprint: [`copy-to-clipboard-error`],
      tags: { errorType: 'clipboard' },
    });
    return false;
  }
};

export const canUseShareApi = (data: ShareData) => {
  return 'canShare' in navigator && navigator.canShare(data);
};
